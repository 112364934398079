































































import {Component, Vue} from "vue-property-decorator";

@Component
export default class AddressView extends Vue {
    loading: boolean = false

    items = { address: "Av. 90, Calle 79k", location: "La Floresta ", province: "Zulia", active: false }
}
